import React, { useEffect, useState } from "react";
import { AutoComplete, Input, Spin, List, Radio, message, Select } from "antd";
import { SearchOutlined, DownOutlined, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { BDOModal, BDOButton, BDOAlert } from '../../common/index';
import { useEngegament } from "../../../hooks/useEngegament";
import { useTeamMembers } from "../../../hooks/useTeamMembers";
import dayjs from "dayjs";
import "./SelfServiceModal.css";

const SelfServiceModal = (props) => {
  const { isModalOpen, onAddButtonClick, onCancel, onAddInModalClick } = props;

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isUUIdsLoading, setIsUUIdsLoading] = useState(true);
  const [isUUIdsMenuOpen, setIsUUIdsMenuOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [engagements, setEngagements] = useState([]);
  const [selectedEngagement, setSelectedEngagement] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const { Option } = Select;



  const { GetAllUUIds, GetAllEngagementsByUUId } = useEngegament();
  const { SelfAddTeamMember, GetSelfAddRoles } = useTeamMembers();

  useEffect(() => {
    const fetchData = async () => {
      const response = await GetAllUUIds();
      if (response && response.status === 200) {
        setOptions(
          response.data.map((x) => ({
            label: `${x.uuid} ${x.auditEntityName}`,
            value: x.uuid,
            key: x.uuid,
            auditEntityName: x.auditEntityName
          }))
        );
      }
      setIsUUIdsLoading(false);
    };

    if (isModalOpen) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    const loadRoles = async () => {
      const response = await GetSelfAddRoles();

      if (response && response.status === 200) {
        setRoles(response.data);
      }
    };

    loadRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (selectedOption) {
      getAllEngagementsByUUId(selectedOption.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  const getAllEngagementsByUUId = async (uuId) => {
    const response = await GetAllEngagementsByUUId(uuId);
    if (response && response.status === 200) {
      setEngagements(response.data);
    } else {
      setEngagements([]);
    }
  };

  const showModal = () => {
    onAddButtonClick();
  };

  const handleAddButtonClick = async () => {
    if (!selectedEngagement) {
      message.error("Please select an engagement.");
      return;
    }
   
    try {
      setIsButtonLoading(true);
      const response = await SelfAddTeamMember(selectedEngagement.engagementYearId, roleId);
   
      if (response && response.status === 200) {
        onAddInModalClick(selectedEngagement);
        setIsUUIdsMenuOpen(false);
        return;
      }
      
      message.error("Failed to add yourself to the engagement. Please try again.");
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setIsButtonLoading(false);
    }
  };

  const handleChange = (option) => {
    var selectedOption = options.find((o) => o.value === option);
    setSelectedOption(selectedOption);
    setSelectedEngagement(null);    
    setInputValue(`${selectedOption.value} ${selectedOption.auditEntityName}`)
  };

  const handleEngagementSelect = (engagement) => {
    setSelectedEngagement(engagement);
  };

  const handleClose = () => {
    setIsUUIdsMenuOpen(false);
    setSelectedOption(null);
    setSelectedEngagement(null);
    setOptions([]);
    setEngagements([]);
    setInputValue("");  
    onCancel();
  };

  return (
    <div>
      <BDOButton style={{marginRight: 20}} onClick={showModal}>
        Self Service {<PlusOutlined style={{ fontSize: "var(--L)" }} />}
      </BDOButton>

      <BDOModal
        title="Add yourself to existing engagement"
        open={isModalOpen}
        onCancel={handleClose}
        onOk={handleAddButtonClick}
        destroyOnClose={true}
        okText="Add Myself"
        okButtonProps={{
          disabled: !selectedEngagement || !roleId || isButtonLoading,
          loading: isButtonLoading,
        }}
      >
        <BDOAlert message={{
            type: "info",
            title: "You can only add yourself to an engagement where one has been set up.",
          }}
        />
        <p className="fieldLabel">Type to search by UUID or entity name</p>

        <AutoComplete
          onSelect={handleChange}
          options={options}
          filterOption={(inputValue, option) => {
            return option.value === inputValue 
            || option.auditEntityName.toLowerCase().startsWith(inputValue.toLowerCase());
          }}
          className="searchEngagement"
          popupClassName="searchEngagementPopup"
          disabled={isUUIdsLoading}
          value={inputValue}
          onChange={setInputValue}
          open={inputValue.length > 0 && isUUIdsMenuOpen}
          onClick={() => setIsUUIdsMenuOpen(true)}
          onBlur={() => setIsUUIdsMenuOpen(false)}
          onSearch={setInputValue}
        >
          <Input
            className="searchEngagementInput"
            addonBefore={<SearchOutlined />}
            addonAfter={
              isUUIdsLoading ? (
                <Spin indicator={<LoadingOutlined />} />
              ) : (
                <DownOutlined style={{ color: "var(--red)" }} onClick={() => setIsUUIdsMenuOpen(!isUUIdsMenuOpen)} />
              )
            }
          />
        </AutoComplete>

        <List
          className="engagements-list"
          header={<div>Select an Engagement</div>}
          bordered
          dataSource={engagements}
          renderItem={(engagement) => (
            <List.Item
              className={`engagement-item ${selectedEngagement === engagement ? "selected" : ""}`}
              onClick={() => handleEngagementSelect(engagement)}
            >
              <Radio
                checked={selectedEngagement === engagement}
                onChange={() => handleEngagementSelect(engagement)}
                className="engagement-radio"
              >
                <div>UUID: {engagement.uuid}</div>
                <div>Entity Name: {engagement.auditEntityName}</div>
                <div>Year End: {dayjs(engagement.yearEnd).format("YYYY-MM-DD")}</div>
                <div>Audit Managers: {engagement.teamMembers.map((tm) => tm.email).join(", ")}</div>
              </Radio>
            </List.Item>
          )}
        />
        <p className="fieldLabel">Select Role</p>
        <Select
          value={roleId}
          onChange={setRoleId}
          placeholder="Select an option"
        >
          {roles.map((option) => (
            <Option key={option.name} value={option.id}>
              {option.name}
            </Option>
          ))}
        </Select>
      </BDOModal>
    </div>
  );
};

export default SelfServiceModal;