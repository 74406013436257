import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "antd";
import {
  EllipsisOutlined,
  FilterOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { BDOTable, BDOButton } from "../common/index";
import NewEngagementModal from "../layout/NewEngagementModal/NewEngagementModal";
import "./Landing.css";
import { useNavigate } from "react-router";
import { appRoutes, buildAppRoute } from "../../config/appRoutes";
import { ButtonActions, Roles } from "../constants/constants";
import { Link } from "react-router-dom";
import { useEngegament } from "../../hooks/useEngegament";
import dayjs from "dayjs";
import { useTableFilter } from "../../hooks/useTableFilter";
import { useMsal } from "@azure/msal-react";
import SelfServiceModal from "../layout/SelfServiceModal/SelfServiceModal";

export const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSelfServiceModalOpen, setIsSelfServiceModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]); // Initialize with empty array
  const { ListEngagementsByUser } = useEngegament();  
  const [tableLoading, setTableLoading] = useState(true); // Initialize with true
  // data control for the table filter options
  const [currentTableData, setCurrentTableData] = useState([]);
  const navigate = useNavigate();

  // Get the logged user account from MSAL
  const { accounts } = useMsal();
  const account = accounts[0];

  useEffect(() => {
    const fetchData = async () => {
      const result = await ListEngagementsByUser(account.username);
      if (result.status === 200) {
        setDataSource(result.data.sort((a, b) => a.uuid - b.uuid));
        setCurrentTableData(result.data.sort((a, b) => a.uuid - b.uuid));
        setTableLoading(false);
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to show the NewEngagementModal when the "Add" button is clicked
  const handleAddButtonClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  // Function to show the Detail component when the "Add" button in the modal is clicked
  const handleAddInModalClick = (option) => {
    setIsModalOpen(false); // Close the modal after showing the Detail component
    navigate(
      buildAppRoute(appRoutes.engagement.create, {
        uuid: option.key,
        entityname: encodeURIComponent(option.label),
      })
    );
  };

  const { handleTableFilter } = useTableFilter(currentTableData);
  const columns = [
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
      filters: handleTableFilter("uuid"),
      onFilter: (value, record) => record.uuid.includes(value),
      filterSearch: true,
      filterIcon: <FilterOutlined />,
      sortOrder: "ascend",
      align: "center",
    },
    {
      title: "Audited entity name",
      dataIndex: "auditedEntityName",
      key: "auditedEntityName",
      filters: handleTableFilter("auditedEntityName"),
      onFilter: (value, record) => record.auditedEntityName.includes(value),
      filterSearch: true,
      filterIcon: <FilterOutlined />,
      align: "center",
    },
    {
      title: "Audit Type",
      dataIndex: "auditType",
      key: "auditType",
      filters: handleTableFilter("auditType"),
      onFilter: (value, record) => record.auditType.match(new RegExp("^" + value + "$")),
      filterIcon: <FilterOutlined />,
      filterSearch: true,
      align: "center",
    },
    {
      title: "Year End",
      dataIndex: "yearEnd",
      key: "yearEnd",
      filters: handleTableFilter("yearEnd"),
      onFilter: (value, record) => record.yearEnd.includes(value),
      filterSearch: true,
      filterIcon: <FilterOutlined />,
      align: "center",
      render: (text) => {
        return (
          <div>
            {new Date(text).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </div>
        );
      },
    },
    {
      title: "Pending",
      dataIndex: "pending",
      key: "pending",
      className: "pending",
      sorter: (a, b) => a.pending - b.pending,
      align: "center",
      render: (text, record) => {
        return <BDOButton shape="circle">{text}</BDOButton>;
      },
    },
    {
      title: "Confirmed",
      dataIndex: "confirmed",
      key: "confirmed",
      className: "confirmed",
      sorter: (a, b) => a.confirmed - b.confirmed,
      align: "center",
      render: (text, record) => {
        return <BDOButton shape="circle">{text}</BDOButton>;
      },
    },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      className: "declined",
      sorter: (a, b) => a.no - b.no,
      align: "center",
      render: (text, record) => {
        return <BDOButton shape="circle">{text}</BDOButton>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      className: "actions",
      render: (record) => {
        const subMenuItems = getMenuItemsByRole(record);
        const menuProps = {
          items: subMenuItems,
          className: "actionMenu",
        };
        return (
          <Dropdown menu={menuProps} placement="right">
            <Button type="primary" icon={<EllipsisOutlined />}></Button>
          </Dropdown>
        );
      },
      align: "center",
    },
  ];

  // Function to get the menu items based on the user's role
  const getButtonByAction = (record, actionName) => {
    switch (actionName) {
      case ButtonActions.Manage:
        return {
          key: "1",
          label: (
            <Link
              type="primary"
              icon={<EllipsisOutlined />}
              to={buildAppRoute(appRoutes.engagement.manage, {
                action: "manage",
                uuid: record.uuid,
                entityname: encodeURIComponent(record.auditedEntityName),
                yearend: dayjs(record.yearEnd).format("DD-MM-YYYY"),
                eyid: record.engagementYearId,
              })}
            >
              Manage
            </Link>
          ),
        };
      case ButtonActions.View:
        return {
          key: "2",
          label: (
            <Link
              type="primary"
              icon={<EllipsisOutlined />}
              to={buildAppRoute(appRoutes.engagement.manage, {
                action: "view",
                uuid: record.uuid,
                entityname: encodeURIComponent(record.auditedEntityName),
                yearend: dayjs(record.yearEnd).format("DD-MM-YYYY"),
                eyid: record.engagementYearId,
              })}
            >
              View
            </Link>
          ),
        };
      default:
        return false;
    }
  };

  const getMenuItemsByRole = (record) => {
    return (record.role === Roles.AuditManager || record.role === Roles.Delegate)
      ? [getButtonByAction(record, ButtonActions.Manage)]
      : record.role === Roles.AuditPartner
      ? [getButtonByAction(record, ButtonActions.Manage)]
      : (
           record.role === Roles.Auditor 
        || record.role === Roles.AuditExpert 
        || record.role === Roles.Specialist 
        || record.role === Roles.QualityReviewer 
        || record.role === Roles.Other
      )
      ? [getButtonByAction(record, ButtonActions.View)]
      : [];
  };

  const tableLoadingProps = {
    spinning: tableLoading,
    indicator: <LoadingOutlined />,
  };

  const handleSelfServiceButtonClick = () => {
    setIsSelfServiceModalOpen(true);
  };

  const handleSelfServiceModalClose = () => {
    setIsSelfServiceModalOpen(false);
  };

  const handleAddMyself = async (option) => {
    setIsSelfServiceModalOpen(false); 
    navigate(buildAppRoute(appRoutes.engagement.manage, {
        action: "manage",
        uuid: option.uuid,
        entityname: encodeURIComponent(option.auditEntityName),
        yearend: dayjs(option.yearEnd).format("DD-MM-YYYY"),
        eyid: option.engagementYearId,
      })
    );
  };

  return (
    <>
      <div>
        <div className="title-container">
          <h3>My Engagements</h3>
          <div className="submit-button-container">
            <SelfServiceModal
              isModalOpen={isSelfServiceModalOpen}
              onAddButtonClick={handleSelfServiceButtonClick}
              onCancel={handleSelfServiceModalClose}
              onAddInModalClick={handleAddMyself}
            />
            <NewEngagementModal
              isModalOpen={isModalOpen}
              onAddButtonClick={handleAddButtonClick}
              onCancel={handleModalClose}
              onAddInModalClick={handleAddInModalClick}
            />
          </div>
        </div>
        <BDOTable
          dataSource={dataSource}
          columns={columns}
          rowKey="engagementYearId"
          loading={tableLoadingProps}
          onChange={(pagination, filters, sorter, extra) => {
            setCurrentTableData(extra.currentDataSource);
          }}
        />
      </div>
    </>
  );
};
