import React, { useCallback, useEffect, useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import "./BDOUpload.css";
import { acceptedExtensions } from "./BDOUploadConfig";
import {Base64} from "js-base64";
const { Dragger } = Upload;

const BDOUpload = (props) => {
  const {fileList, onChange, onDrop, onRemove, disabled } = props;
  const [selectedFileList, setSelectedFileList] = useState(fileList ? [...fileList] : []);

  const createBlob = useCallback(() => {
    const fileAtob = Base64.atob(fileList[0].content);
    return  { name: fileList[0].name, originFileObj: new Blob([fileAtob], { type: fileList[0].type }) };
  }, [fileList]);

  useEffect(() => {
    if (fileList && fileList.length > 0) {
      setSelectedFileList([createBlob()]);
    } else {
      setSelectedFileList([]);
    }
  }, [createBlob, fileList]);

  const uploadProps = {
    multiple: false,
    className: "bdo-file-upload-container",
    maxCount: 1,
    accept: acceptedExtensions,
    onChange: onChange,
    onDrop: onDrop,
    fileList: selectedFileList,
    onRemove: onRemove,
    disabled,
    beforeUpload: (_file, _fileList) => false,
  };

  return (
    <Dragger className="bdo-file-upload-container" {...uploadProps}>
      <div className="bdo-upload-drag-container">
        {selectedFileList.length === 0 && (
          <>
            <p className="ant-upload-drag-icon">
              <CloudUploadOutlined className="bdo-upload-icon" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </> )}

      </div>
    </Dragger>
  );
};

export default BDOUpload;
